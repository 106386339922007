import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import GeneralHeader from "../../../components/Headers/GeneralHeader.js";
import { useSelector } from "react-redux";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
} from "reactstrap";

const path = require("../../../externals/path.json").path;

export default function InventoryLocations() {
  const user = useSelector((state) => state.isLogged.user);
  const [expensesTypeList, setExpensesTypeList] = useState([]);
  const [expenseTypeId, setExpenseTypeId] = useState();
  const [ExpenseTypeName, setExpenseTypeName] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const Toggle = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    onBindTableData();
  }, []);
  const onBindTableData = () => {
    axios
      .get(path + "/expensestype", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setExpensesTypeList(result.data.data);
        }
      });
  };

  function onAddNewHandler() {
    Toggle();
    setIsUpdate(false);
    setExpenseTypeId();
    setExpenseTypeName();
  }

  function onSaveDataHandler() {
    const Data = {
      typeName: ExpenseTypeName,
      username: user.id,
    };
    if (isUpdate === true) {
      axios
        .put(path + "/expensestype/" + expenseTypeId, Data, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindTableData();
            setShowModal(false);
            setIsUpdate(false);
            Swal.fire({
              title: "THANKS",
              text: "the type updated",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "type did not updated please contact administrator",
            icon: "warning",
          });
        });
    } else {
      axios
        .post(path + "/expensestype", Data, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindTableData();
            setShowModal(false);
            Swal.fire({
              title: "THANKS",
              text: "new type Added",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "Zone did not added please contact administrator",
            icon: "warning",
          });
        });
    }
  }
  function EditDataHandler(expenseTypeId, Index) {
    // let item = itemList.find((o) => o.id === item.itemId);
    axios
      .get(path + "/expensestype/id/" + expenseTypeId, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setIsUpdate(true);
          setShowModal(true);
          setExpenseTypeName(result.data.data.typeName);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "warning",
          text: `there is some error while get Zone with  number ${Index} data please contact administrator`,
          icon: "warning",
        });
      });
  }
  function onDeleteHandler(id, index) {
    Swal.fire({
      title: 'Do you want to delete Type  "' + index + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/expensestype/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindTableData();
              Swal.fire("Type Deleted!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the type did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }

  return (
    <>
      <GeneralHeader />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h2 className="mb-0">Expenses Type</h2>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={(e) => onAddNewHandler()}
                      size="md"
                    >
                      New Type
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <div style={{ height: "70vh", overflow: "scroll" }}>
                <Table
                  className=" align-items-center table-flush"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col"> name</th>

                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expensesTypeList.map(function (item, index) {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{item.typeName}</td>
                          <td className="">
                            <div>
                              <Button
                                size="sm"
                                color="warning"
                                onClick={() => {
                                  EditDataHandler(item.id, index + 1);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                size="sm"
                                color="danger"
                                onClick={() =>
                                  onDeleteHandler(item.id, index + 1)
                                }
                              >
                                Delete
                              </Button>{" "}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showModal}
        toggle={Toggle}
        className="modal-lg"
        style={{ maxWidth: "1500px", width: "100%" }}
      >
        <ModalHeader toggle={Toggle}>New Invenotry</ModalHeader>
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <div className="pl-lg-2">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={ExpenseTypeName}
                            id="name"
                            placeholder="Type Name .."
                            type="text"
                            onChange={(e) => setExpenseTypeName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onSaveDataHandler()}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={Toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
